import mainSelect from '@/components/main-select/index.vue'
import secondaryButton from '@/components/buttons/secondary-button/index.vue'
import accordion from './accordion/index.vue'
import {mapGetters, mapMutations} from "vuex";


export default {
  name: "bonus",
  data() {
    return {
      attrs: [
        // This is a single attribute
        {
          // Attribute type definitions

          highlight: {
            style: {
              backgroundColor: '$color-green',
            }
          },
          content: {
            style: {
              // backgroundColor: '#fff',
              color: 'white',
            }
          }, // Boolean, String, Object
          // Your custom data object for later access, if needed
          // We also need some dates to know where to display the attribute
          // We use a single date here, but it could also be an array of dates,
          //  a date range or a complex date pattern.
          dates: new Date(),
          // You can optionally provide dates to exclude
          excludeDates: null,
          // Think of `order` like `z-index`
          order: 0
        }
      ],
      payload: {
        sum: 0,
        present: '',
      },
      referrals: [
        {
          name: 'Сергієнко Антоніна',
          all_time: 876,
          notactive: 11,
          active: 22,
          orders: [{
            number: 124536666666,
            date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
            sum: 32016.25 - Math.floor(Math.random() * 400)
          }]
        },
        {
          name: 'Наталия Делав',
          all_time: 876,
          notactive: 11,
          active: 333,
          orders: [{
            number: 124536666666,
            date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
            sum: 32016.25 - Math.floor(Math.random() * 400)
          },
            {
              number: 124536666666,
              date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
              sum: 32016.25 - Math.floor(Math.random() * 400)
            },
            {
              number: 124536666666,
              date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
              sum: 32016.25 - Math.floor(Math.random() * 400)
            },
          ]
        },
        {
          name: 'Делав Делав',
          all_time: 876,
          notactive: 11,
          active: 777,
          orders: [
            {
              number: 124536666666,
              date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
              sum: 32016.25 - Math.floor(Math.random() * 400)
            },
            {
              number: 124536666666,
              date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
              sum: 32016.25 - Math.floor(Math.random() * 400)
            },
            {
              number: 124536666666,
              date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
              sum: 32016.25 - Math.floor(Math.random() * 400)
            },
            {
              number: 124536666666,
              date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
              sum: 32016.25 - Math.floor(Math.random() * 400)
            },
          ]
        },
        {
          name: 'Антоненко Тетяна',
          all_time: 876,
          notactive: 22,
          active: 444,
          orders: [{
            number: 124536666666,
            date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
            sum: 32016.25 - Math.floor(Math.random() * 400)
          },
          {
            number: 124536666666,
            date: new Date(+(new Date()) - Math.floor(Math.random() * 10000000000)).toLocaleDateString(),
            sum: 32016.25 - Math.floor(Math.random() * 400)
          },
          ]
        },

      ]
    }
  },


  methods: {
    ...mapMutations({
      changePopupUserCosmetologist: 'user/CHANGE_POPUP_USER_COSMETOLOGIST',
    })
  },

  components: {
    mainSelect,
    secondaryButton,
    accordion
  },
  computed: {
    ...mapGetters({
      showPopupCosmetologist: 'user/changeCosmetologist',

    }),
    sum() {
      const arr = []
      for (let i = 1; i < 10; i++) {
        arr.push(i + ' грн')
      }
      return arr
    },
    present() {
      const arr = []
      for (let i = 1; i < 11; i++) {
        if (i % 2 === 0) {
          arr.push(i + ' %')
        }
      }
      return arr
    }
  },
}

