import {VueSlideToggle} from 'vue-slide-toggle';

export default {
  name: "referrals",
  components: {
    VueSlideToggle
  },
  props: ['refer'],
  data() {
    return {
      openItem: false,
    };
  },
  methods: {

  }

};
